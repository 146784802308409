import React, { useState } from "react";
import Section from "../../components/section";

import bgD7 from "../../images/background-deco-7.svg";
import appstore from "../../images/appstore.svg";
import googlePlay from "../../images/google-play.png";
import appGallery from "../../images/app-gallery.png";

const Download = () => {
  return (
    <Section backgrounds={{ lg: [bgD7], sm: [bgD7] }} autoHeight id="download">
      <div className="flex flex-grow flex-col justify-center items-center mb-60">
        <p className="text-white text-6xl mb-4 text-center">下載應用</p>
        <p className="text-white text-xl mb-4 text-center">
          無條件開始免費試用300字！
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center">
          <a href="https://play.google.com/store/apps/details?id=com.opensertk.cangjie_practice_ss_directload">
            <img
              className="market-badge"
              alt="立即下載 Google Play"
              src={googlePlay}
            />
          </a>
          <a href="https://apps.apple.com/hk/app/%E5%80%89%E9%A0%A1-%E9%80%9F%E6%88%90%E7%B7%B4%E7%BF%92%E5%B7%A5%E5%85%B7/id1600176379">
            <img
              className="market-badge"
              alt="立即下載 Appstore"
              src={appstore}
            />
          </a>
          <a href="https://appgallery.huawei.com/app/C102894033">
            <img
              className="market-badge"
              alt="立即下載 App Gallery"
              src={appGallery}
            />
          </a>
        </div>
      </div>
    </Section>
  );
};

export default Download;
